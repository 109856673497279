@import url( 'css/reset.css');
/* color:  #3ac0ca */
@font-face {
    font-family: 'source-han-sans-bold';
    src: url('fonts/source-han-sans/bold.otf') format('opentype');
}
@font-face {
    font-family: 'source-han-sans-regular';
    src: url('fonts/source-han-sans/regular.otf') format('opentype');
}
@font-face {
    font-family: 'source-han-sans-medium';
    src: url('fonts/source-han-sans/medium.otf') format('opentype');
}

html {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Microsoft YaHei", "微软雅黑", Arial, sans-serif !important;
    /* -webkit-font-smoothing: antialiased; */
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-image: url("./img/universe.png");
    background-repeat: repeat-x repeat-y;
    background-position: 0 0;
    background-color: #002c4d;
    /* background: url("./img/universe.png") repeat-x repeat-y 0px 0px #002c4d;
    background-color: black; */
    background-size: cover;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Microsoft YaHei", "微软雅黑", Arial, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: #002c4d; */
}

.earth {
    width: 57%;
    display: block;
    height: 100%;
    position: absolute;
}

.earth > img {
    width: 100%;
    height: 1005;
}

li {
    font-family: 'source-han-sans-regular';
}

code {
    font-family: 'source-han-sans-regular';
}

.label {
    font: 500;
    font-size: 50px;
    color: white;
}

.province-ul {
    width: 140px;
    color: white;
    border: 2px solid #759775;
    list-style-type: none;
    border-radius: 1px;
    right: 20px;
    padding: 0;
    background-color: #33503385;
}

.province-ul li {
    border-bottom: 3px solid black;
    background-color: #00000014;
    text-align: center;
    padding: 20px;
}

.provinceNumber {
    color: white;
    display: block;
    width: 20px;
    height: 20px;
    background-color: green;
    border-radius: 100px;
    padding: 15px;
    text-align: center;
    position: absolute;
    top: -39px;
    left: -68px;
}

.svgLine {
    z-index: 121 !important;
    position: absolute;
    stroke: #4ff6f9;
}

.province-list-container {

    background: linear-gradient(to left, rgba(58, 192, 202, 0.3), transparent);
    /* background-size: 100px 100%; */
    background-repeat: no-repeat;
    background-position-x: right;
    position: fixed;
    right: 0;
    z-index: 130;
}

.province-ul-container {
    position: relative;
    width: 190px;
    height: 100vh;
    margin-top: 0;
    top: -35px;
    margin-right: 20px;
}

.province-list {
    height: 90vh;
    margin-right: 30px;
    float: right;
}

.province-list > li > div.wide {
    display: flex;
    position: relative;
    width: 100px;
    place-content: space-between;
}

.province-list > li > div {
    float: right;
    border-radius: 2px;
    /* border: 1px solid #3ac0ca; */
    /* background: linear-gradient(#3ac0ca, transparent, #3ac0ca); */
    background: linear-gradient(to bottom, #3ac0ca, transparent 20%, transparent 75%, transparent 10%, #3ac0ca);
    width: 66px;
    padding: 5px 7px;
    font-family: 'source-han-sans-regular';
    font-size: 16px;
    cursor: pointer;
    color: #4ff6f9;
    text-align: center;
    margin-bottom: 10px;
    border-right: 1px solid #3ac0ca;
    border-left: 1px solid #3ac0ca;
    box-shadow: 0 0 0 transparent;
    /* transition: all 0.3s ease-in-out; */
}
.province-list > li > div:hover {
    border-radius: 2px;
    /* border: 1px solid #3ac0ca; */
    /* background: linear-gradient(#3ac0ca, transparent, #3ac0ca); */
    background: linear-gradient(to bottom, rgb(58, 192, 202), transparent 10%, #3ac0ca);
    box-shadow: 0 0 10px rgba(58, 192, 202, 0.7);
}

.os-scrollbar .os-scrollbar-handle {
    background: rgb(71 225 231) !important;
}

.province-vertical-line {
    position: absolute;
    width: 2px;
    height: 100vh;
    background-color: #4df1f5;
    right: 24px;
}

.globe-title {
    line-height: 26px;
    position: fixed;
    top: 55px;
    right: 240px;
    width: 300px;
    min-width: 300px;
    font-size: 17px;
    font-family: 'source-han-sans-bold';
    color: #4ff6f9;
}

.province-detail-container {
    position: fixed;
    top: 163px;
    right: 14%;
    width: 260px;
    z-index: 130;
}

.province-number-block {
    position: relative;
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
    color: #3ac0ca;
}

.hospital-list-container ul {
    padding: 20px;
    border: 1px solid #3ac0ca;
    color: #3ac0ca;
    border-radius: 1px;
    outline: 1px solid rgb(58, 192, 202, 0.4);
    outline-offset: 5px;
    min-width: 205px;
    max-width: 220px;
    max-height: 300px;
}

.hospital-list-container ul li {
    background: url('./img/globe-li-img.png') no-repeat 0 7px;
    background-size: 15px;
    padding: 5px 5px 5px 25px;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-family: 'source-han-sans-medium';
    color: #4ff6f9;
}

.province-number {
    display: flex;
    position: relative;
    justify-content: center;
    text-align: center;
    width: 80px;
    background: url("./img/number-base.png") no-repeat 9px 46px;
    font-family: 'source-han-sans-bold';
    font-size: 40px;
    background-size: 62px;
    height: 90px;
    color: #4ff6f9;
    overflow: hidden;
}

.province-number > span {
    position: absolute;
    text-align: center;
    top: 23px;
    text-shadow: 2px -1px 0 rgb(76 199 201 / 75%), 5px -2px 0 rgb(0 0 0);
}

.province-number > span.fade-in {
    animation: fade-in-animation 0.5s ease-in;
}

.province-number > span.slide-up {
    animation: slideUp 0.5s ease-in;
}

.province-title {
    min-height: 30px;
    max-width: 200px;
    min-width: 100px;
    position: relative;
    overflow: hidden;
    display: flex;
    font-family: 'source-han-sans-bold';
    color: #4ff6f9;
    font-size: 22px;
    align-self: end;
}

.province-title > span {
    display: flex;
    position: absolute;
    transform: rotate(0deg);
    transform-origin: left bottom;
    opacity: 1;
}

.province-title > span.rotate-slide-up {
    animation: rotateSlideUp 0.3s linear;
}
@keyframes rotateSlideUp {
    0% {
        opacity: 0;
        transform: rotate(45deg);
    }

    100% {
        opacity: 1;
        transform: rotate(0deg);
    }
}
@keyframes fade-in-animation {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}
@keyframes slideUp {
    0% {
        opacity: 0.4;
        top: 50px;
    }

    70% {
        opacity: 0.5;
        top: 15px;
    }

    100% {
        opacity: 1;
        top: 23px;
    }
}

.start-line-connection {
    position: absolute;
    z-index: 15;
    width: 30px;
    height: 1px;
    background-color: #4ff6f9;
    bottom: 9px;
    left: -20px;
}

.map-markder-container {
    display: flex;
    position: absolute;
    z-index: 12;
    flex-direction: column;
    align-items: center;
}

.number-circle-block {
    justify-content: center;
    text-align: center;
    align-items: center;
    position: relative;
    display: flex;
}

.outer-circle {
    position: absolute;
    border-radius: 50%;
    /* height: calc(100% + 15px);
    width: calc(100% + 15px); */
    /* padding: 3px; */
    /* border: 5px solid rgb(79 246 249 / 28%); */
    animation: rippleEffectOuterCircle 1.8s cubic-bezier(0.25, 0.1, 0.9, 1) infinite;
    animation-delay: 0.01s;
}

.inner-circle {
    /* height: 100%;
    width: 100%; */
    position: absolute;
    /* border: 8px solid #00f9e7; */
    border-radius: 50%;
    animation: rippleEffect 1.8s cubic-bezier(0.25, 0.1, 0.9, 1) infinite;
}
@keyframes rippleEffect {
    0% {
        /* transform: scale(0); */
        width: 100%;
        height: 100%;
        opacity: 0;
        border: 0 solid #00f9e7;
    }

    50% {
        /* transform: scale(1); */
        width: 100%;
        height: 100%;
        opacity: 0.8;
        border: 15px solid #00f9e7;
    }

    100% {
        /* transform: scale(1); */
        width: 100%;
        height: 100%;
        opacity: 0;
        border: 25px solid #00f9e7;
    }

}
@keyframes rippleEffectOuterCircle {
    0% {
        padding: 0;
        height: 40px;
        width: 40px;
        opacity: 0;
        border: 2px solid rgb(79 246 249 / 28%);
    }

    50% {
        padding: 0;
        height: calc(100% + 20px);
        width: calc(100% + 20px);
        opacity: 1;
        border: 10px solid rgb(79 246 249 / 28%);
    }

    100% {
        padding: 5px;
        height: calc(100% + 20px);
        width: calc(100% + 20px);
        opacity: 0;
        border: 30px solid rgb(79 246 249 / 28%);
    }
}

.map-markder {
    display: flex;
    text-align: center;
    color: #56fbfc;
    width: 40px;
    height: 40px;
    font-size: 28px;
    font-family: 'source-han-sans-bold';
    align-items: center;
    justify-content: center;
}
@keyframes blink-animation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

.blink {
    animation: blink-animation 2s infinite;
}

.map-title {
    margin-bottom: 20px;
    color: #56fbfc;
    font-size: 32px;
    font-family: 'source-han-sans-bold';
}

.province-top-bar {
    position: relative;
    right: 15px;
}

.hospital-container-top-bar {
    display: block;
    position: relative;
    width: 30px;
    height: 3px;
    bottom: 3px;
    background-color: #4ff6f9;
    border-radius: 2px;
    margin: auto;
}

.hospital-container-bottom-bar {
    display: block;
    position: relative;
    width: 100px;
    height: 5px;
    bottom: 3px;
    background-color: #4ff6f9;
    border-radius: 2px;
    margin: auto;
}

.globe-china {
    position: fixed;
    z-index: -1;
    /* margin-left: -350px; */
    /* margin-top: 60px; */
}

.canvasBlock {
    top: -11vh;
    border-radius: 100%;
    left: -12vh;
    position: relative;
    overflow: hidden;
    width: 130vh;
    height: 140vh;
    background-color: none;
}

.canvasContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 12;
}

.canvassParticleID {
    position: absolute;
    width: 100%;
    height: 100%;
    /* border-radius: 44%; */
    background-color: none;
    top: 0;
}

.canvasParticle {
    /* background: url("./img/raw-earth.png") no-repeat 0px 0px rgba(0, 0, 0, 0); */
    background-size: 100%;
    background-color: transparent !important;
    /* border-right: 2px solid #00f9e730;
    /* box-shadow: 0 0 145px #277c76; */
}

.leafLetContainer {
    position: absolute;
    top: 99px;
    left: 120px;
    transform: rotate(356deg);
    opacity: 0.0;
    z-index: 12;
}

.particle-earth-main {
    height: 100vh;
    width: 60%;
    position: fixed;
    display: block;
    overflow: hidden;
    z-index: 12;
    border-radius: 0 212px 52px 0;
}
@keyframes fade-in-left {
    0% {
        opacity: 0;
        transform: translateX(-30%);
    }

    50% {
        opacity: 0.5;
        transform: translateX(-15%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-in-left {
    animation: fade-in-left 0.5s ease-out;
}
@keyframes fade-in-right {
    0% {
        opacity: 0;
        transform: translateX(30%);
    }

    50% {
        opacity: 0.5;
        transform: translateX(15%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-in-right {
    animation: fade-in-right 0.5s ease-out;
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade-out {
    animation: fade-out 2s ease-out;
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-in {
    animation: fade-in 1s ease-out;
}

.earth-glow {
    box-shadow: 11px 0 51px 0 #47e1e7;
    background-color: none;
    left: -22vh;
    top: -10vh;
    margin: auto;
    display: block;
    width: 135vh;
    height: 135vh;
    position: absolute;
    z-index: 0;
    border-radius: 100%;
}

.svgCustomChinaMapGroup {
    transform-origin: top left;
    transform-box: fill-box;
    /* transform: translate(-183px, -122px) scale(1); */
}

.chinaBoundryOutBound {}

.svgCustomChinaMap {
    position: absolute;
    width: 771px;
    height: auto;
    top: -12px;
    left: -16px;
    resize: both;
}

.svgCustomChinaMapContainer {
    position: absolute;
    z-index: 20;
    width: 100%;
}

.raw-earth-container {
    position: relative;
    z-index: 12;
}

.raw-earth-container > img {
    position: fixed;
    width: auto;
    height: 100vh;
}

.full-screen-button-container {
    top: 5px;
    position: absolute;
    right: 29px;
    z-index: 200;
}

.full-screen-svg {
    margin-right: 7px;
    color: #4df1f5;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.1s linear;
}
.full-screen-svg:hover {
    opacity: 1;
}

.loader {
    border-radius: 5px;
    border: 1px solid rgb(79, 246, 249, 0.7);
    padding: 1px;
    width: 150px;
    height: 7px;
    background-color: none;
    position: relative;
    overflow: hidden;
}
.loader:before {
    content: "";
    position: absolute;
    border-radius: 5px;
    top: 1px;
    left: -100%;
    width: 100%;
    height: 80%;
    background-color: #4ff6f9;
    animation: loaderAnimation 1s linear infinite;
}
@keyframes loaderAnimation {
    0% {
        left: -100%;
    }

    100% {
        left: 100%;
    }
}
@media (min-width: 768px) {}
@media (min-width: 1024px) {}
@media (min-width: 1320px) {}
@media (min-width: 1441px) {

    .province-title {
        font-size: 24px;
    }

    .province-detail-container {
        right: 15%;
    }

    .hospital-list-container ul {
        min-width: 250px;
        max-width: 270px;
        max-height: 350px;
    }

    .hospital-list-container ul li {
        font-size: 22px;
    }

    .globe-title {
        font-size: 22px;
    }
}
@media (min-width: 1920px) {

    .province-title {
        font-size: 24px;
    }

    .province-detail-container {
        right: 15%;
    }

    .hospital-list-container ul {
        min-width: 300px;
        max-width: 320px;
        max-height: 350px;
    }

    .hospital-list-container ul li {
        font-size: 22px;
    }

    .globe-title {
        font-size: 24px;
    }
}
@media (min-width: 2090px) {}
@media (min-width: 3840) {}
